import React from "react";

type Props = {
	label: any,
	className?: string,
	id?: string,
};

export default function Button(props: Props) {
	let { label, className, id, ...rest } = props;
	let classNames = className ? ["button", className].join(" ") : "button";
	return (
	<button {...rest} id={id} className={classNames}>
		{label}
		</button>
);
}
