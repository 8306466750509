import React from "react";
import Button from "../Button";
import Spinner from "../Spinner";

import "./style.scss";

export default function SignButton(props) {
    const buttonLabel =
        <span className="inner-content">
             {`Slutför köp`}
            {props.loading && <Spinner isButtonSpinner={true}/>}
        </span>;

    return <div className="sign-agreement">
        <Button
            id="sign-agreement"
            data-testid="sign-agreement-button"
            label={buttonLabel}
            disabled={props.disabled}
            onClick={props.onClickHandler}
        />
    </div>
}

